export const ARTIST = { value: 'Artist', label: 'Artist' };
export const COMPOSER = { value: 'Composer', label: 'Composer' };
export const BAND = { value: 'Band', label: 'Band' };
export const COMPOSER_LYRICIST = { value: 'ComposerLyricist', label: 'Composer Lyricist' };
export const CONTRIBUTOR = { value: 'Contributor', label: 'Contributor' };
export const DIRECTOR = { value: 'Director', label: 'Director' };
export const LYRICIST = { value: 'Lyricist', label: 'Lyricist' };
export const ORIGINAL_PUBLISHER = { value: 'OriginalPublisher', label: 'Original Publisher' };
export const PRIMARY_MUSICIAN = { value: 'PrimaryMusician', label: 'Primary Musician' };
export const PRODUCER = { value: 'Producer', label: 'Producer' };
export const SOLOIST = { value: 'Soloist', label: 'Soloist' };
export const STUDIO_PERSONNEL = { value: 'StudioPersonnel', label: 'Studio Personnel' };
export const FEATURED_ARTIST = { value: 'FeaturedArtist', label: 'Featured Artist' };
export const LABEL = { value: 'Label', label: 'Label' };
export const MAIN_ARTIST = { value: 'MainArtist', label: 'Primary Artist' };
export const MANAGEMENT = { value: 'Management', label: 'Management' };
export const ENGINEER = { value: 'Engineer', label: 'Engineer' };
export const MASTERING_ENGINEER = { value: 'MasteringEngineer', label: 'Mastering Engineer' };
export const MIXING_ENGINEER = { value: 'MixingEngineer', label: 'Mixing Engineer' };
export const REMIXER = { value: 'Remixer', label: 'Remixer' };

export const soundRecordingRoles = [
  ARTIST,
  BAND,
  COMPOSER,
  COMPOSER_LYRICIST,
  CONTRIBUTOR,
  ENGINEER,
  FEATURED_ARTIST,
  LABEL,
  LYRICIST,
  MAIN_ARTIST,
  MANAGEMENT,
  MASTERING_ENGINEER,
  MIXING_ENGINEER,
  PRIMARY_MUSICIAN,
  PRODUCER,
  SOLOIST,
  STUDIO_PERSONNEL,
];

export const creditRoles = [
  ARTIST,
  BAND,
  COMPOSER,
  COMPOSER_LYRICIST,
  CONTRIBUTOR,
  DIRECTOR,
  ENGINEER,
  LYRICIST,
  MASTERING_ENGINEER,
  MIXING_ENGINEER,
  ORIGINAL_PUBLISHER,
  PRIMARY_MUSICIAN,
  PRODUCER,
  REMIXER,
  SOLOIST,
  STUDIO_PERSONNEL,
];

export const artistRoles = [MAIN_ARTIST, FEATURED_ARTIST, REMIXER];

export const displayRoles = [...artistRoles].concat(...soundRecordingRoles);

export const labelRoles = new Map([
  ['admin', 'Label Admin'],
  ['member', 'Label Member'],
]);
